import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Provider from "react-redux/lib/components/Provider";
import {applyMiddleware, createStore} from "redux";
import reducer from './redux/reducer';
import thunk from "redux-thunk";
import {AppConnected} from "./App";
import {composeWithDevTools} from "redux-devtools-extension";
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Provider store={createStore(reducer,  composeWithDevTools(applyMiddleware(thunk)))}>
              <AppConnected/>
          </Provider>
      </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root')
);
