import "./SendFeedBackButton.css"
import {useHistory} from "react-router-dom";

export function SendFeedBackButton({ data, buttonClick, userId, textData, type }) {
    const history = useHistory();
    const handleSubmit = async () => {
        buttonClick();
        const scriptURL = 'https://script.google.com/macros/s/AKfycbywrkpypvnDBGQOcjEVKJIqWJML2uiV5AacQKATC213HsafJ9t9sup-pgGBz2NOMFlW/exec';
        const formData = new FormData();

        formData.append('user_id', userId.toString());
        formData.append('it_does_not_have_cursors_that_i_need', (data[0].checked ? 1 : 0).toString());
        formData.append('the_extension_is_not_convenient_in_using', (data[1].checked ? 1 : 0).toString());
        formData.append('it_is_not_what_i_expected', (data[2].checked ? 1 : 0).toString());
        formData.append('it_is_not_working_properly', (data[3].checked ? 1 : 0).toString());
        formData.append('it_has_a_negative_effect_on_my_browser', (data[4].checked ? 1 : 0).toString());
        formData.append('text', textData);
        formData.append('email', '');


        try {
            fetch(scriptURL, { method: 'POST', body: formData})
                .then(response => void response)
                .catch(error => console.error('Error!', error.message));
        } catch (e) {
            console.error('Error!', e.message)
        }
    }

    const showView = () => {
      if (type === "INACTIVE") {
          return <div className={"inactive-btn-feed-back"}>
              <div className={"txt-style-feed-back"}>
                  Send your feedback
              </div>
          </div>
      } else if (type === "ACTIVE") {
          return <div onClick={async () =>{
              await handleSubmit()
              history.push(`/pool-thank-you?userId=${userId}`)
             }} className={"active-btn-feed-back"}>
              <div className={"txt-style-feed-back"}>
                  Send your feedback
              </div>
          </div>
      }
    }

    return (
        <div>
            {showView()}
        </div>
    )
}
