import {useEffect} from "react";
import "./UpdateExtensionPopup.css"

export function UpdateExtensionPopup({ setShouldShowUpdateExtensionPopup }) {
    useEffect(() => {
        localStorage.setItem('updatedOnV3', 'success')
    }, []);

    const onPopupClose = () => {
        setShouldShowUpdateExtensionPopup(false)
    }

    return(
        <div className="popup_overlay">
            <div className="update_extension_popup cursor-hover">
                <div className="popup_logo">
                    <img src={process.env.PUBLIC_URL + "/update_popup_logo.svg"} alt="update_popup_logo"/>
                </div>
                <h2 className="popup_title cursor-hover">
                    Congratulations with the global update
                </h2>
                <div className="popup_preview_wrapper">
                    <div className="popup_preview portal_preview">
                        <div className="preview_image">
                            <img src={process.env.PUBLIC_URL + "/update_popup_left_preview.png"} alt="update_popup_left_preview"/>
                        </div>
                        <div className="preview_title">
                            New huge directory with cursors
                        </div>
                        <div className="preview_subtitle">
                            Hundreds of collections including thousands of free cursors
                        </div>
                    </div>
                    <div className="popup_preview extension_preview">
                        <div className="preview_image">
                            <img src={process.env.PUBLIC_URL + "/update_popup_right_preview.png"} alt="update_popup_left_preview"/>
                        </div>
                        <div className="preview_title">
                            New extension interface
                        </div>
                        <div className="preview_subtitle">
                            Now it's much easier to use and save cursors
                        </div>
                    </div>
                </div>
                <div className="popup_btn pointer-hover" onClick={onPopupClose}>
                    Get Started
                </div>
            </div>
        </div>
    )
}
