import "./RobloxPopup.css"

export function RobloxPopup({ setShouldShowRobloxPopup }) {
    const onPopupClose = () => {
        setShouldShowRobloxPopup(false)
        localStorage.setItem("roblox", '');
    }

    const openGiveawayPage = () => {
        localStorage.setItem("roblox", '');
        setShouldShowRobloxPopup(false)
        window.open('/robloxland2/index.html', "_blank").focus()
    }

    return(
        <div className="popup_overlay">
            <div className="popup_bordered">
                <div className="popup congrats cursor-hover">
                    <div className="popup_logo">
                        <img src={process.env.PUBLIC_URL + "/congrats.svg"} alt="congrats"/>
                    </div>
                    <h2 className="popup_title cursor-hover">
                        Congratulations, you have successfully confirmed the installation
                    </h2>
                    <div className="popup_btn pointer-hover" onClick={openGiveawayPage}>
                        Back to Giveaway page
                    </div>
                    <div className="close_btn" onClick={onPopupClose}>
                        <img src={process.env.PUBLIC_URL + "/close_btn.svg"} alt="close"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
